<template>
  <div class="search">
    <Nav></Nav>
    <div class="search-content minheight">
      <div class="search-info">
        <div class="keyWord">
          <span>关键字：</span>
          <el-input v-model="searchInfo.keyword" clearable></el-input>
          <el-button @click="onSearch">搜索</el-button>
          <span v-if="heightSearch === false">您尚未登录，登录后可使用高级搜索</span>
        </div>
        <div class="search-height" v-if="heightSearch">
          <div class="author-con">
            <div class="author">
              <div class="author-search">作者：</div>
              <el-input placeholder="请输入作者" v-model="searchInfo.author" clearable></el-input>
            </div>
            <div class="keyword-select">
              <el-checkbox v-model="searchInfo.keyInMain">搜索稿件正文</el-checkbox>
              <el-checkbox v-model="searchInfo.keyInPic">搜索图片说明</el-checkbox>
            </div>
          </div>
          <div class="search-store">
            <div class="store">所属库：</div>
            <div class="store-list">
              <el-checkbox-group v-model="storeIds" @change="postStore">
                <el-checkbox v-for="searcgStore in canserachStore" :key="searcgStore.id" :label="searcgStore.id">{{ searcgStore.name }}</el-checkbox>
              </el-checkbox-group>
            </div>
          </div>
          <div class="input-line">
            <div class="event">
              <div class="event-name">发生时间：</div>
              <div class="input-line-content">
                <el-date-picker
                  v-model="searchInfo.eventFrom"
                  type="date"
                  placeholder="选择发生开始日期"
                  :editable="false"
                  :clearable="false"
                  value-format="yyyy-MM-dd"
                ></el-date-picker
                >-
                <el-date-picker
                  v-model="searchInfo.eventTo"
                  type="date"
                  placeholder="选择发生结束日期"
                  :editable="false"
                  :clearable="false"
                  value-format="yyyy-MM-dd"
                  @keyup.enter.native="onSearch"
                ></el-date-picker>
              </div>
            </div>
            <div class="innerEdit">
              <div class="innertime-name">上传时间：</div>
              <div class="input-line-content">
                <el-date-picker
                  v-model="searchInfo.uploadFrom"
                  type="date"
                  placeholder="选择发生开始日期"
                  :editable="false"
                  :clearable="false"
                  value-format="yyyy-MM-dd"
                ></el-date-picker
                >-
                <el-date-picker
                  v-model="searchInfo.uploadTo"
                  type="date"
                  placeholder="选择发生结束日期"
                  :editable="false"
                  :clearable="false"
                  value-format="yyyy-MM-dd"
                  @keyup.enter.native="onSearch"
                ></el-date-picker>
              </div>
            </div>
          </div>
          <div class="input-line">
            <div class="addr">
              <div class="addr-name">检索地址：</div>
              <div class="input-line-content">
                <el-cascader
                  :options="address"
                  :props="addr_picker_props"
                  width="320"
                  filterable
                  clearable
                  change-on-select
                  separator=">"
                  v-model="addr_picker_options"
                  @keyup.enter.native="onSearch"
                ></el-cascader>
              </div>
            </div>
            <div class="type">
              <div class="type-name">检索类型：</div>
              <div class="input-line-content">
                <el-cascader
                  :options="types"
                  width="320"
                  :props="types_picker_props"
                  filterable
                  change-on-select
                  clearable
                  separator=">"
                  v-model="type_picker_options"
                  @keyup.enter.native="onSearch"
                ></el-cascader>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="search-page">
        <div class="pic-count">符合当前条件的稿件共有：{{ postCount }}</div>
        <div class="page-container">
          <el-pagination
            @current-change="handleCurrentChange"
            :current-page.sync="pager.currentPage"
            :page-size="20"
            :hide-on-single-page="true"
            layout="prev, pager, next, jumper"
            :total="pager.total"
          ></el-pagination>
        </div>
      </div>
      <div class="search-con">
        <div class="post-list">
          <div class="post-box" v-for="(k, index) in postList" :key="k.id">
            <div class="part1">
              <div class="xu">序号</div>
              <div>{{ index + 1 }}</div>
            </div>
            <div class="part2">
              <div class="yu">预览</div>
              <div :style="{ backgroundImage: `url(` + k.imageURL + `)` }" @click="onLookPost(k.id)"></div>
            </div>
            <div class="part3">
              <div class="time">时间/地点/图片</div>
              <div>
                <div>上传时间：{{ k.upTime }}</div>
                <div>地点：{{ k.eventAddrNamePath }}</div>
                <div>图片数：{{ k.countPic }}</div>
              </div>
            </div>
            <div class="part4">
              <div class="title">图片标题/内容</div>
              <div class="four">
                <div class="four-title">{{ k.title }}</div>
                <div class="con">{{ k.mainText }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="search-page">
        <div class="pic-count">符合当前条件的稿件共有：{{ postCount }}</div>
        <div class="page-container">
          <el-pagination
            @current-change="handleCurrentChange"
            :current-page.sync="pager.currentPage"
            :page-size="20"
            :hide-on-single-page="true"
            layout="prev, pager, next, jumper"
            :total="pager.total"
          ></el-pagination>
        </div>
      </div>
    </div>
    <Bottom></Bottom>
  </div>
</template>

<script>
import RD from '@/api/RD'
import Nav from '@/components/nav.vue'
import Bottom from '@/components/bottom.vue'

export default {
  components: { Nav, Bottom },
  name: 'Index',
  data: function () {
    return {
      pager: { currentPage: 1, total: 0 },
      postList: [],
      postCount: '', // 稿件数
      searchInfo: {
        order: '1',
        appr: 2,
        keyInMain: true,
        keyword: '',
        storeIds: [1],
      },
      heightSearch: false, // 高级搜索
      storeIds: [1],
      addr_picker_props: {
        value: 'id',
        label: 'name',
        children: 'subs',
        checkStrictly: true,
      },
      addr_picker_options: [], // 默认地址
      type_picker_options: [], // 默认地址
      types_picker_props: {
        value: 'id',
        label: 'name',
        children: 'subs',
        checkStrictly: true,
      },
      address: [],
      types: [],
      canserachStore: [], // 能够搜索的库
    }
  },
  created() {
    this.searchInfo.keyword = this.safeKeyword(this.$route.query.keyword)
    this.reload()
  },
  beforeRouteUpdate(to, from, next) {
    if (to.fullPath != from.fullPath) {
      next()
      this.reload()
    }
  },
  mounted() {
    this.reload()
  },
  methods: {
    reload() {
      var thiz = this
      console.log('serachquery', this.$route.query)
      RD.pure()
        .user()
        .sync()
        .then((data) => {
          thiz.canserachStore = data.searchStores
          thiz.heightSearch = true
        })
      RD.query()
        .query(this.searchInfo, this.pager.currentPage, 20)
        .then((data) => {
          data.list.forEach((i) => {
            i.imageURL = RD.pic().id(i.frontPicId).mImage_URL()
          })
          this.postList = data.list
          this.pager = data.pager
        })
      thiz.postStore()
    },
    safeKeyword(keyword) {
      if (keyword === undefined) {
        return ''
      }
      return keyword.replace('<', '').replace('>', '').replace('/', '').replace('(', '').replace(')', '').replace('{', '').replace('}', '').replace(';', '')
    },
    // filter() {
    //   var thiz = this
    //   thiz.$route.query.keyWord = thiz.$route.query.keyWord.replace('<', '')
    //   thiz.$route.query.keyWord = thiz.$route.query.keyWord.replace('>', '')
    //   thiz.$route.query.keyWord = thiz.$route.query.keyWord.replace('/', '')
    //   thiz.$route.query.keyWord = thiz.$route.query.keyWord.replace('(', '')
    //   thiz.$route.query.keyWord = thiz.$route.query.keyWord.replace(')', '')
    //   thiz.$route.query.keyWord = thiz.$route.query.keyWord.replace('{', '')
    //   thiz.$route.query.keyWord = thiz.$route.query.keyWord.replace('}', '')
    //   thiz.$route.query.keyWord = thiz.$route.query.keyWord.replace(';', '')
    //   thiz.searchInfo.keyword = '' + thiz.$route.query.keyWord
    //   thiz.onSearch()
    // },
    handleCurrentChange(val) {
      var thiz = this
      thiz.pager.currentPage = val
      thiz.onSearch(thiz.page)
    },
    onSearch() {
      var thiz = this
      thiz.postStore()
      console.log('xujiwu', thiz.searchInfo)

      // 地址
      if (thiz.addr_picker_options.length !== 0) {
        thiz.search.addrId = thiz.addr_picker_options[thiz.addr_picker_options.length - 1]
      }
      // 类型
      if (thiz.type_picker_options.length !== 0) {
        thiz.search.typeId = thiz.type_picker_options[thiz.type_picker_options.length - 1]
      }
      if (thiz.searchInfo.storeIds == undefined) {
        thiz.$message('必须勾选至少一个库')
      } else {
        RD.query()
          .query(thiz.searchInfo, this.pager.currentPage, 20)
          .then((data) => {
            data.list.forEach((i) => {
              i.imageURL = RD.pic().id(i.frontPicId).mImage_URL()
            })
            thiz.postList = data.list
            thiz.pager = data.pager
          })
      }
    },
    // 库选择
    postStore() {
      this.searchInfo.storeIds = []
      this.storeIds.forEach((value) => {
        this.searchInfo.storeIds.push(parseInt(value))
      })
    },
    // 查看稿件
    onLookPost(postId) {
      let routerUrl = this.$router.resolve({
        path: '/post.html',
        query: { id: postId },
      })
      window.open(routerUrl.href, '_blank')
    },
  },
}
</script>

<style scoped lang="less">
.search {
  height: auto;
  background-color: #f1f1f1;
}
@media all and (min-width: 800px) {
  .search-content {
    width: 120rem;
    margin: 0 auto;
    .search-info {
      height: auto;
      font-size: 1.3rem;
      .keyWord {
        .el-input {
          width: 20rem;
        }
        .el-button {
          margin-left: 1rem;
        }
        span {
          margin-left: 1rem;
        }
      }
      .search-height {
        margin-top: 1rem;
        .search-store {
          margin-top: 1rem;
          display: flex;
          flex-wrap: wrap;
          font-size: 1.3rem;
          text-align: left;
          .store {
            width: 6%;
            text-align: left;
          }
          .store-list {
            margin: 0rem;
            .el-checkbox-group {
              display: flex;
              flex-wrap: wrap;
              .el-checkbox {
                width: 8rem;
              }
            }
          }
        }
        .input-line {
          text-align: left;
          margin: 1rem 0;
          padding: 0rem;
          display: flex;
          .addr,
          .type {
            display: flex;
            margin-right: 1rem;
            .addr-name,
            .type-name {
              width: 30%;
              height: 5rem;
              line-height: 5rem;
            }
            .input-line-content {
              width: 70%;
            }
          }
          .event,
          .innerEdit {
            display: flex;
            .event-name,
            .innertime-name {
              height: 5rem;
              line-height: 5rem;
            }
            .input-line-content {
              margin-top: 0.5rem;
            }
          }
        }
        .author-con {
          display: flex;
          height: 5rem;
          position: relative;
          .author {
            display: flex;
            text-align: left;
            .author-search {
              height: 5rem;
              width: 8rem;
              line-height: 5rem;
            }
          }
          .keyword-select {
            height: 5rem;
            line-height: 5rem;
            margin-left: 3rem;
          }
        }
      }
    }
    .search-page {
      display: felx;
      position: relative;
      font-size: 1.3rem;
      font-weight: bold;
      height: 7rem;
      margin-top: 2rem;
      .pic-count {
        width: 35rem;
        height: 5rem;
        text-align: left;
        position: absolute;
        top: 0rem;
      }
      .page-container {
        width: 65rem;
        height: 5rem;
        position: absolute;
        right: 2rem;
        top: 0rem;
      }
    }
    .search-con {
      display: flex;
      border: 0.1rem solid #ccc;
      min-height: 40rem;
      .post-list {
        .post-box {
          height: 25rem;
          border-bottom: 0.1rem solid #ccc;
          display: flex;
          font-size: 1.3rem;
          .part1 {
            width: 7rem;
            border-right: 0.1rem solid #ccc;
            .xu {
              height: 5rem;
              line-height: 5rem;
              border-bottom: 0.1rem solid #ccc;
            }
            div {
              height: 20rem;
              line-height: 20rem;
            }
          }
          .part2 {
            width: 32rem;
            border-right: 0.1rem solid #ccc;
            .yu {
              text-align: center;
              height: 5rem;
              line-height: 5rem;
              border-bottom: 0.1rem solid #ccc;
            }
            div {
              height: 78%;
              width: 100%;
              background: no-repeat 50% / cover;
              vertical-align: middle;
            }
          }
          .part3 {
            width: 25rem;
            border-right: 0.1rem solid #ccc;
            .time {
              text-align: center;
              height: 5rem;
              line-height: 5rem;
              border-bottom: 0.1rem solid #ccc;
            }
            div {
              text-align: left;
              height: 3rem;
              line-height: 3rem;
            }
          }
          .part4 {
            width: 55.5rem;
            border-right: 0.1rem solid #ccc;
            .title {
              text-align: center;
              height: 5rem;
              line-height: 5rem;
              border-bottom: 0.1rem solid #ccc;
            }
            .four {
              height: 19.9rem;
              overflow-y: auto;
              .four-title {
                text-align: left;
                margin-left: 0.5rem;
                margin-bottom: 1rem;
              }
              .con {
                text-align: left;
                margin-left: 0.5rem;
              }
            }
          }
        }
      }
    }
  }
}
@media all and (max-width: 800px) {
  .search-content {
    width: 750px;
    margin: 0 auto;
    .search-info {
      height: auto;
      font-size: 1.3rem;
      .keyWord {
        .el-input {
          width: 20rem;
        }
        .el-button {
          margin-left: 1rem;
        }
        span {
          margin-left: 1rem;
        }
      }
      .search-height {
        margin-top: 1rem;
        .search-store {
          margin-top: 15px;
          display: flex;
          flex-wrap: wrap;
          font-size: 1.3rem;
          text-align: left;
          height: 25px;
          .store {
            width: 80px;
            text-align: left;
            font-size: 12px;
          }
          .store-list {
            margin: 0rem;
            width: 500px;
            .el-checkbox-group {
              display: flex;
              flex-wrap: wrap;
              .el-checkbox {
                width: 80px;
              }
            }
          }
        }
        .input-line {
          text-align: left;
          margin: 1rem 0;
          padding: 0rem;
          display: flex;
          .addr,
          .type {
            display: flex;
            margin-right: 1rem;
            .addr-name,
            .type-name {
              width: 30%;
              height: 5rem;
              line-height: 5rem;
              font-size: 12px;
            }
            .input-line-content {
              width: 70%;
            }
          }
          .event,
          .innerEdit {
            display: flex;
            .event-name,
            .innertime-name {
              height: 5rem;
              line-height: 5rem;
              font-size: 12px;
            }
            .input-line-content {
              margin-top: 0.5rem;
            }
          }
        }
        .author-con {
          display: flex;
          height: 5rem;
          position: relative;
          .author {
            display: flex;
            text-align: left;
            .author-search {
              height: 5rem;
              width: 8rem;
              line-height: 5rem;
              font-size: 12px;
            }
          }
          .keyword-select {
            height: 5rem;
            line-height: 5rem;
            margin-left: 3rem;
          }
        }
      }
    }
    .search-page {
      display: felx;
      position: relative;
      font-size: 1.3rem;
      font-weight: bold;
      height: 7rem;
      margin-top: 2rem;
      .pic-count {
        width: 35rem;
        height: 5rem;
        text-align: left;
        position: absolute;
        font-size: 12px;
        top: 0rem;
      }
      .page-container {
        width: 65rem;
        height: 5rem;
        position: absolute;
        right: 2rem;
        top: 0rem;
      }
    }
    .search-con {
      display: flex;
      border: 0.1rem solid #ccc;
      min-height: 40rem;
      .post-list {
        .post-box {
          height: 250px;
          border-bottom: 0.1rem solid #ccc;
          display: flex;
          font-size: 16px;
          .part1 {
            width: 70px;
            border-right: 0.1rem solid #ccc;
            .xu {
              height: 50px;
              line-height: 50px;
              border-bottom: 0.1rem solid #ccc;
            }
            div {
              height: 200px;
              line-height: 200px;
            }
          }
          .part2 {
            width: 320px;
            border-right: 0.1rem solid #ccc;
            .yu {
              text-align: center;
              height: 5rem;
              line-height: 5rem;
              border-bottom: 0.1rem solid #ccc;
            }
            div {
              height: 78%;
              width: 100%;
              background: no-repeat 50% / cover;
              vertical-align: middle;
            }
          }
          .part3 {
            width: 355px;
            border-right: 1px solid #ccc;
            font-size: 16px;
            .time {
              text-align: center;
              height: 50px;
              line-height: 50px;
              border-bottom: 1px solid #ccc;
            }
            .content {
              text-align: left;
              height: 195px;
              line-height: 30px;
              overflow-y: auto;
            }
          }
        }
      }
    }
  }
}
</style>
